// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "checkboxall", "checkboxval", "total","checkboxzero","checkboxbp","output_gleps", "output_bp"];
  
  connect() {
    // console.log(`Hello Stimulus !`)
  }
  


  selectallcheckbox(){
    // console.log(`Hello selectallcheckbox`)
    this.checkboxvalTargets.forEach((item) => {item.checked=this.checkboxallTarget.checked})
  }
  
  updatecheckboxall(){
    // console.log(`Hello updatecheckboxall`)
    let bool=true
    this.checkboxvalTargets.forEach((item) => {
      bool = bool && item.checked
      })
    this.checkboxallTarget.checked=bool
  }
  
  selectallcheckboxzero(){
    //console.log(`Hello selectallcheckboxzero`)
    this.checkboxvalTargets.forEach((item) => {
      if(parseFloat(item.getAttribute("valtotal"))== 0){
      item.checked=this.checkboxzeroTarget.checked        
      }
      else{}
      })
  }

  selectallbigprizes(){
    //console.log(`Hello selectallcheckboxzero`)
    this.checkboxvalTargets.forEach((item) => {
      if(parseFloat(item.getAttribute("bptotal"))!= 0){
      item.checked=this.checkboxbpTarget.checked        
      }
      else{}
      })
  }  
  
  updatetotal(){
    let tot=0
    // console.log(`Hello updatetotal`)
    this.checkboxvalTargets.forEach((item) => {
      if(item.checked){
        tot+=parseFloat(item.getAttribute("valtotal"))
      }
      else {}
    })
    // console.log(`Hello ${tot}`)
    this.totalTarget.textContent=tot.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  updatealltotal(){
    let tot=0
    let glepstot=0
    let bptot=0
    // console.log(`Hello updatetotal`)
    this.checkboxvalTargets.forEach((item) => {
      if(item.checked){
        tot+=parseFloat(item.getAttribute("valtotal"))
        glepstot+=parseFloat(item.getAttribute("gleptotal"))
        bptot+=parseFloat(item.getAttribute("bptotal"))
      }
      else {}
    })
    // console.log(`Hello ${tot}`)
    this.totalTarget.textContent=tot.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    this.output_glepsTarget.textContent=glepstot.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    this.output_bpTarget.textContent=bptot.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  }
  
  check_winner_details(){
    var list = []
  var output = { "gleps": this.output_glepsTarget, "bp": this.output_bpTarget}
    this.checkboxvalTargets.forEach( (item) => {
      if(item.checked){
        list.push(item.getAttribute("value"))
      }
    })
    const result= this.doAjax("GET","check_winner_details", {"val_list": list, "auth": '12345678'},output).then( result => ( this.process_message(result,event,output) ) );
  }
  
  process_message(result,event,output){
    var message = result
    var gleps = message['gleps'].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    var bp = message['bp'].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    output['gleps'].innerHTML= gleps
    output['bp'].innerHTML= bp
  }
  
  
  
  // perform ajax call async
  async doAjax(ajaxtype,uremote,inputdata,args,output) {
      let result;
      try {
          result = await $.ajax({
              type: ajaxtype,
              url: uremote,
              data: inputdata
          });
          return result;
      } catch (error) {
          //output.innerHTML="<div class='alert alert-danger' role='alert'>".concat("await error: ",error,"</div>");
          console.error(error);
      }
  }
  
  
}