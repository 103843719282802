// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import scratchlao3 from 'images/scratchlao-003.jpg';
import scratchlao4 from 'images/scratchlao-004.jpg';
import scratchlao5 from 'images/scratchlao-005.jpg';
import scratchlao6 from 'images/scratchlao-006.jpg';
import scratchlao7 from 'images/scratchlao-L0001.jpg';
import scratchlao8 from 'images/scratchlao-L0002.jpg';
import timor1 from 'images/timor-001.jpg';
import timor2 from 'images/timor-002.jpg';
import timor3 from 'images/timor-003.jpg';



export default class extends Controller {
  static targets = [ "output", "source", "image" ];
 
  connect() {
    this.myUrl = this.data.get("url");
    this.myEmail = this.data.get("email");
    this.myId = this.data.get("id");
    
    //console.log("url is: "+ this.myUrl );
  }
  // process a message for check_booklet
  process_message(result,event,output,imageflag,image) {
    console.log("ajax result: ",result);
    var cflag = "alert-danger";
    var message="<div class='alert ";
    var msg3="";
    var imagehash =  {"timor": {"001": timor1, "002": timor2, "003": timor3}, 
                       "scratchlao": {"003": scratchlao3, "004": scratchlao4,"005": scratchlao5,
                         "006": scratchlao6, "L0001": scratchlao7, "L0002": scratchlao8} };
 
    //console.log(imagehash);
    if (result["success"]) { cflag = "alert-warning" ;
         console.log("success result: ",result);
         msg3=result["msg"];
        if (!result["validated"]) {cflag = "alert-success" ;}
        if (result["email"]==this.myEmail) {cflag = "alert-success" ;} ;
        if (imageflag) { console.log("update image");
        var ndiv=`<img class='mr-3 img-responsive' src='${imagehash[result["owner"]][result["vr"]]}' />`;
        image.innerHTML=ndiv;
                         };
          };
    // need to fix email check for winner lookup
    if (!result["success"]) { msg3=" Error Message: not found<br>ຜິດ: ບໍ່ພົບຂໍ້ຄວາມ"; };
          output.innerHTML=message.concat(cflag,"' role='alert'>",msg3,"</div>");

  };   
  
  
  // update DOM with processing alert
  mark_processing(output)  {
      output.innerHTML="<div class='alert alert-danger' role='alert'>Processing...<br>ລໍຖ້າ...</div>";
  }
  
  mark_image(image)  {
      image.innerHTML="<div class='alert alert-danger' role='alert'>Image here...<br>ລໍຖ້າ...</div>";
  }
  
  mark_short(output)  {
      output.innerHTML="<div class='alert alert-warning' role='alert'>Data too short...<br>ລະຫັດສັ້ນເກີນໄປ...</div>";
  }
  
  // perform ajax call async
  async doAjax(ajaxtype,uremote,inputdata,args,output) {
      let result;
      try {
          result = await $.ajax({
              type: ajaxtype,
              url: uremote,
              data: inputdata
          });
          return result;
      } catch (error) {
          //output.innerHTML="<div class='alert alert-danger' role='alert'>".concat("await error: ",error,"</div>");
          console.error(error);
      }
  }
  
  check_booklet(event) {
    const output = this.outputTarget;
    
    // output.innerHTML="<div class='alert alert-danger' role='alert'>Processing...</div>";
    const element = this.sourceTarget;
    var input = element.value;
    const uremote = this.myUrl;
    const email = this.myEmail;
    const id = this.myId
    if (input.length <= 14)  { this.mark_short(output);
                              return; };
                              //need to fix this
    // if (input.length == 63) { image=input.slice(0,4) ; };
    if (input.length >= 21 && input.length <60) input=input.slice(0,6)+input.slice(10,22);
    const noop=this.mark_processing(output);
    console.log(`check_booklet: input after mods is: ${input} url: ${uremote}`);
     
    const result=  this.doAjax("GET",this.myUrl,{ "scan" : input, "auth": 'aa23456789', "id": id},output).then(result =>(this.process_message(result,event,output,false,null)  ) );
    
  };
  
  check_winner(event) {
    const output = this.outputTarget;
    const image = this.imageTarget;
    
    // output.innerHTML="<div class='alert alert-danger' role='alert'>Processing...</div>";
   
    const element = this.sourceTarget;
    var input = element.value;
    const uremote = this.myUrl;
    //const email = this.myEmail;
    if (input.length <= 14) { this.mark_short(output);
                              this.mark_image(image);
                              return; }
    const noop=this.mark_processing(output);
    this.mark_image(image);
    console.log(`check_winner: input after mods is: ${input} url: ${uremote}`);
     
    const result=  this.doAjax("GET",this.myUrl,{ "scan" : input, "auth": '12345678'},output).then(result =>(this.process_message(result,event,output,true,image)  ) );
    
  };
  
 
  
}
