// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"


export default class extends Controller {
  static targets = [ "closebutton" ];
  
  connect() {
    // console.log(`Hello Stimulus !`)
    this.myUrl = this.data.get("url");
    this.myEmail = this.data.get("email");
    this.myId = this.data.get("id");    
  }
  
  // perform ajax call async
  async doAjax(ajaxtype,uremote,inputdata,args,output) {
    let result;
    try {
        result = await $.ajax({
            type: ajaxtype,
            url: uremote,
            data: inputdata
        });
        return result;
    } catch (error) {
        //output.innerHTML="<div class='alert alert-danger' role='alert'>".concat("await error: ",error,"</div>");
        console.error(error);
    }
  }


  close(event){
    if(this.closebuttonTarget.disabled){
        console.log(`Already Closed!`);
    }
    else{
        var element = this.closebuttonTarget;
        var output = this.closebuttonTarget
        const id= element.getAttribute("id");
        // console.log(`${id}`);
        const uremote = this.myUrl;
        // console.log(`${uremote}`);
        const result= this.doAjax("POST",uremote, {"id": id},output).then( result => ( this.disable(result,event,output) ) );
    }
  }

  disable(result,event,output){
    // console.log(`In disable ${output.className}`);
    output.innerHTML = "Closed";
    output.disabled = "true";
    output.classList.remove("btn-primary");
    output.classList.add("btn-secondary");
    output.classList.add("disabled");
  }


  
}