// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import scratchlao3 from 'images/scratchlao-003.jpg';
import scratchlao4 from 'images/scratchlao-004.jpg';
import scratchlao5 from 'images/scratchlao-005.jpg';
import scratchlao6 from 'images/scratchlao-006.jpg';
//crab and line
import scratchlao7 from 'images/scratchlao-L0001.jpg'; 
import scratchlao8 from 'images/scratchlao-L0002.jpg';
import timor1 from 'images/timor-001.jpg';
import timor2 from 'images/timor-002.jpg';
import timor3 from 'images/timor-003.jpg';



export default class extends Controller {
  static targets = [ "output", "source", "image"];
 
  connect() {
    this.myUrl = this.data.get("url");
    this.myEmail = this.data.get("email");
    this.myId = this.data.get("id");
            
    //console.log("url is: "+ this.myUrl );
  }
  
  
  
  // process a message for check_booklet
  process_message_booklet(result,event,output,imageflag,image) {
    //console.log("booklet ajax result: ",result);
    var cflag = "alert-danger";
    var message="<div class='alert ";
    var msg3="";
    var imagehash =  {"timor": {"001": timor1, "002": timor2, "003": timor3}, 
                       "scratchlao": {"003": scratchlao3, "004": scratchlao4,
                          "005": scratchlao5, "006": scratchlao6,  "L0001": scratchlao7,  "L0002": scratchlao8} };
 
    //console.log(imagehash);
    if (result["success"]) { cflag = "alert-warning" ;msg3=result["msg"];
        if (result["success_location"]) {cflag = "alert-success" ;}
        if (imageflag) { console.log("update image");
        var ndiv=`<img class='mr-3 img-responsive' src='${imagehash[result["owner"]][result["vr"]]}' />`;
        image.innerHTML=ndiv;
                         };
          };
    // need to fix email check for winner lookup
    if (!result["success"]) { msg3=" Error Message: not found<br>ຜິດ: ບໍ່ພົບຂໍ້ຄວາມ";};
    output.innerHTML=message.concat(cflag,"' role='alert'>",this.message_displayer(msg3),"</div>");

    // this.formController.apply_class()
  };   
  
  
  // process a message for check_booklet IN TRANSFERS
  process_message_booklet_transfers(result,event,output,imageflag,image) {
    //console.log("booklet ajax result: ",result);
    var cflag = "alert-danger";
    var message="<div class='alert ";
    var msg3="";
    var imagehash =  {"timor": {"001": timor1, "002": timor2, "003": timor3}, 
                       "scratchlao": {"003": scratchlao3, "004": scratchlao4,"005": scratchlao5,
                        "006": scratchlao6 ,  "L0001": scratchlao7,  "L0002": scratchlao8} };
 
    //console.log(imagehash);
    if (result["success"]) { cflag = "alert-warning" ;;msg3=result["msg"];
        if (result["success_location"]) {cflag = "alert-success" ;}
        if (imageflag) { console.log("update image");
        var ndiv=`<img class='mr-3 img-responsive' src='${imagehash[result["owner"]][result["vr"]]}' />`;
        image.innerHTML=ndiv;
                         };
          };
    // need to fix email check for winner lookup
    if (!result["success"]) { msg3=" Error Message: not found<br>ຜິດ: ບໍ່ພົບຂໍ້ຄວາມ";};
          output.innerHTML=message.concat(cflag,"' role='alert'>",this.message_displayer(msg3),"</div>");

    // this.formController.apply_class()
  };   


  // process a message for check_winner
  process_message_winner(result,event,output,imageflag,image) {
    //console.log("winner ajax result: ",result);
    var cflag = "alert-danger";
    var message="<div class='alert ";
    var msg3="";
    var imagehash =  {"timor": {"001": timor1, "002": timor2, "003": timor3}, 
                       "scratchlao": {"003": scratchlao3, "004": scratchlao4,"005": scratchlao5,
                        "006": scratchlao6 ,  "L0001": scratchlao7,  "L0002": scratchlao8} };
 
    //console.log(imagehash);
    if (result["success"]) { cflag = "alert-warning" ;msg3=result["msg"];
        if (!result["validated"]) {cflag = "alert-success" ;}
        if (result["email"]==this.myEmail) {cflag = "alert-success" ;} ;
        if (imageflag) { console.log("update image");
        var ndiv=`<img class='mr-3 img-responsive' src='${imagehash[result["owner"]][result["vr"]]}' />`;
        image.innerHTML=ndiv;
                         };
          };
    // need to fix email check for winner lookup
    if (!result["success"]) { msg3=" Error Message: not found<br>ຜິດ: ບໍ່ພົບຂໍ້ຄວາມ";};
          output.innerHTML=message.concat(cflag,"' role='alert'>",msg3,"</div>");

    // this.formController.apply_class()
  }; 



  
  
  // update DOM with processing alert
  mark_processing(output)  {
      output.innerHTML="<div class='alert alert-secondary' role='alert'>Processing...<br>ລໍຖ້າ...</div>";
  }
  
  mark_image(image)  {
      image.innerHTML="<div class='alert alert-secondary' role='alert'>Image here...<br>ລໍຖ້າ...</div>";
  }
  
  mark_short(output)  {
      output.innerHTML="<div class='alert alert-secondary' role='alert'>Data too short...<br>ລະຫັດສັ້ນເກີນໄປ...</div>";
  }
  
  // perform ajax call async
  async doAjax(ajaxtype,uremote,inputdata,args,output) {
      let result;
      try {
          result = await $.ajax({
              type: ajaxtype,
              url: uremote,
              data: inputdata
          });
          return result;
      } catch (error) {
          //output.innerHTML="<div class='alert alert-danger' role='alert'>".concat("await error: ",error,"</div>");
          console.error(error);
      }
  }
  
  check_api_test(event){
    //this is the functions that deals with transfers    
    const output = this.outputTarget;
    
    output.innerHTML="<div class='alert alert-danger' role='alert'>Processing...</div>";
  
    
   // curl -u api@estormtech.com:telmor1234@ -X POST -d '{\"virn\":\"001001tel1234577\", \"serial\":\"003003001948001\",  \"customer_type\":\"agent\", \"msisdn\":\"73312365\", \"txid\":\"testtxid\"}' -H \"Content-Type: application/json\" \"https://tms.timor-scratch.com/api/v1/winners/001001tel1234577.json\"
     
    const result=  this.doAjax("GET","/api/v1/winners/001001tel1234577.json",{"virn":"001001tel1234577", "serial":"003003001948001",  "customer_type":"agent", "msisdn":"73312365", "txid":"testtxid" },output).then(result =>(output.innerHTML="<div class='alert alert-success' role='alert'>".concat(result["msg"].concat("</div>"))      )); 
        //output.innerHTML="<div class='alert alert-success' role='alert'>".concat(result.concat("</div>")) ) );
    
    
  }
  
  
  check_booklet_transfers(event){
    //this is the functions that deals with transfers    
    const output = this.outputTarget;
    
    // output.innerHTML="<div class='alert alert-danger' role='alert'>Processing...</div>";
    const element = this.sourceTarget;
    var input = element.value;
    const uremote = this.myUrl;
    const email = this.myEmail;
    const id = this.myId;
    const loc = this.data.get("location")
    
    
    if (input.length <= 14)  { this.mark_short(output);
                              return; };
    if (input.length >= 20) input=input.slice(0,6)+input.slice(10,22);
    const noop=this.mark_processing(output);
    // console.log(`check_booklet: input after mods is: ${input} url: ${uremote} location: ${loc}`);
     
    const result=  this.doAjax("GET",this.myUrl,{ "scan" : input, "auth": 'aa23456789', "id": id, "location": loc},output).then(result =>(this.process_message_booklet_transfers(result,event,output,false,null)  ) );
    
    
  }
  
  check_booklet(event) {
    
    const output = this.outputTarget;
    
    // output.innerHTML="<div class='alert alert-danger' role='alert'>Processing...</div>";
    const element = this.sourceTarget;
    var input = element.value;
    const uremote = this.myUrl;
    const email = this.myEmail;
    const id = this.myId;
    const loc = this.data.get("location")
    const check = this.valid_input_check(input,15)
   
    if (check)  { 

    const noop=this.mark_processing(output);
    //console.log(`check_booklet: input after mods is: ${input} url: ${uremote} location: ${loc}`);
     
    const result=  this.doAjax("GET",this.myUrl,{ "scan" : input, "auth": 'aa23456789', "id": id, "location": loc},output).then(result =>(this.process_message_booklet(result,event,output,false,null)  ) );
    }
    else{
      this.mark_short(output);
      // this.formController.apply_class()
      return; 
    }
    
    
  };
  
  check_winner(event) {
    const output = this.outputTarget;
    const image = this.imageTarget;
    
    // output.innerHTML="<div class='alert alert-danger' role='alert'>Processing...</div>";
   
    const element = this.sourceTarget;
    var input = element.value;
    const uremote = this.myUrl;
    const email = this.myEmail;
   
   const check = this.valid_input_check(input,15)
    
    if (check){
      const noop=this.mark_processing(output);
      this.mark_image(image);
      //console.log(`check_winner: input after mods is: ${input} url: ${uremote}`);
       
      const result=  this.doAjax("GET",this.myUrl,{ "scan" : input, "auth": '12345678'},output).then(result =>(this.process_message_winner(result,event,output,true,image)  ) );
    }
  else {
    this.mark_short(output);
    this.mark_image(image);
    // this.formController.apply_class()
    return;}
  };
  
  
  
  get formController() {
    let name = this.data.get("controller-name")
    //console.log(`controller name: form-controller${name}`)
    return this.application.getControllerForElementAndIdentifier(document.getElementById(`form-controller${name}`), "form")
  }
  
  
  
  valid_input_check(input,min_caracters){
    let string = input
    const min = min_caracters
    if (input==""){return false}
    if (input.search('virn')>0 || input.search('serial')>0 || input.search('book')>0){return false}
    if (input.length < min){return false}
    if (input.length >= min){return true}
    else {
      console.log(`valid_input_check problem ${input}`);
      return true
      }
  }
  
  message_displayer( msg ){
      //console.log(`message: ${msg}`)
      let result = ""
      if ( Array.isArray( msg ) ){
        //console.log(`array`)
        result.concat("<ul>")
        msg.forEach( (e) => {
          if ( Array.isArray( e ) ){
            result = result.concat("<li><ul>")
            e.forEach( (l) => {
              result = result.concat("<li>",l,"</li>")
              //console.log(`message_displayer: ${result}`)  
            })
            result = result.concat("</ul></li>")
          }
          else {
            result = result.concat("<li>",e,"</li>")
            //console.log(`message_displayer: ${result}`)            
          }
        })
        result.concat("</ul>")
        return result
      }
      else {
        //console.log(`not array`)
        //console.log(`message_displayer: ${result}`)
        result = msg
        return result
      }
  }

  
  
}
