// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

// THIS SCRIPT IS FOR FORM MANAGEMENT. TO USE IN VALIDATION AND TRANSFER
// THIS CODE IS NOW TIED UP WITH LOOKUP2 CONTROLLER
// Funcionnalities
// 1. updateinputs(): The controller look for a value in target "location" // and set it in all "inputdiv" elements
// 2. enablesubmittest(): The controller expects all targets "inputdiv" to have "data-lookup2-ajaxresult" field which is nil/true/false. Submit button is deactivated unless all non nil inputdiv are true


export default class extends Controller {
  static targets = [ "published", "unpublished", "published_output", "unpublished_output"];
 
  connect() {
    this.mytest = this.data.get("test");
    //console.log(`hello form ${this.mytest}`);
    this.hello()
  }
  
  hello(){
    // #1: get value from target "location" and set controller data
    console.log(`hello jackpots`);
    }

  refresh_published(event){ 
      var output = this.published_outputTarget
      console.log(`published`);
        const result=  this.doAjax("GET","published_jackpots", {"auth": '12345678'},output).then(result =>(this.process_result(result,event,output)  ) );
    }
  refresh_unpublished(event){ 
      var output = this.unpublished_outputTarget
      console.log(`unpublished`);
        const result=  this.doAjax("GET","unpublished_jackpots", {"auth": '12345678'},output).then(result =>(this.process_result(result,event,output)  ) );

    }

  process_result(result,event,output) {
    output.innerHTML = ''
    result.forEach((item) => { 
        var row = output.insertRow()
        row.innerHTML = item.prize_value
    })
  }

  
  // perform ajax call async
  async doAjax(ajaxtype,uremote,inputdata,args,output) {
    let result;
    try {
        result = await $.ajax({
            type: ajaxtype,
            url: uremote,
            data: inputdata
        });
        return result;
    } catch (error) {
        //output.innerHTML="<div class='alert alert-danger' role='alert'>".concat("await error: ",error,"</div>");
        console.error(error);
    }



}    

}