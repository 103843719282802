// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

// THIS SCRIPT IS FOR FORM MANAGEMENT. TO USE IN VALIDATION AND TRANSFER
// THIS CODE IS NOW TIED UP WITH LOOKUP2 CONTROLLER
// Funcionnalities
// 1. updateinputs(): The controller look for a value in target "location" // and set it in all "inputdiv" elements
// 2. enablesubmittest(): The controller expects all targets "inputdiv" to have "data-lookup2-ajaxresult" field which is nil/true/false. Submit button is deactivated unless all non nil inputdiv are true


export default class extends Controller {
  static targets = [ "location", "inputdiv", "submit"];
 
  connect() {
    this.mytest = this.data.get("test");
    //console.log(`hello form ${this.mytest}`);
    this.updateinputs()
  }
  
  updateinputs(){
    // #1: get value from target "location" and set controller data
    const loc = this.locationTarget.value
    console.log(`dropdown value ${loc}`);
    this.data.set("location", loc)
    console.log(`controller location ${this.data.get("location")}`);
    
    // #2: copy data from controller to target inputdiv shared attribute
    this.inputdivTargets.forEach((item) => {
      //console.log(`Changing Element ${item.getAttribute("data-lookup2-id")} from ${item.getAttribute("data-lookup2-location")}`)
      item.setAttribute("data-lookup2-location",this.data.get("location"))
      //console.log(`to: ${item.getAttribute("data-lookup2-location")}`)
      })
  }

  /*
  // THIS IS AN OLD ONE
  enablesubmittest(){
  this.apply_class()
  let nilcount=0;
  let maxelement=this.inputdivTargets.length
  console.log(`${maxelement}`)
  let criteria = "";
  let isvalid = true;
  this.inputdivTargets.forEach((item) => {
    criteria = item.getAttribute("data-lookup2-ajaxresult");
    console.log(`input criteria value ${criteria}`);
    if (criteria=="nil"){nilcount=nilcount+1;}
    if (criteria=="true"){isvalid = isvalid && true;}
    if (criteria=="false"){isvalid = isvalid && false;}
    else {}
  })
  console.log(`input isvalid value ${isvalid}`)
  if(nilcount==maxelement){isvalid=false;console.log(`empty form`);}
  this.submitTargets.forEach((item) => {
    $(item).prop("disabled",!isvalid)
    })
  }
  */
  
  enablesubmit(){
    let successcount = $(document).find(".card-scan.alert-success").length
    let failcount = $(document).find(".card-scan.alert-danger").length
    let bool = ( successcount == 0 || failcount > 0 )
    
    //console.log(`success: ${successcount} fails: ${failcount} bool: ${bool}`)
    
    this.submitTargets.forEach((item) => {
      $(item).prop("disabled",bool)
      })
  }
  
  
  apply_class(){
    
    $(document).find(".card-scan").filter(function(){
    let results = $(this).find(".result").length;
    let success = $(this).find(".alert-success").length;
    let fails = $(this).find(".alert-danger, .alert-warning").length;
    //console.log(`id ${$(this).attr("id")}`);
    //console.log(`results ${results} success ${success}`);
    if(results == success){
      $(this).removeClass('alert-success alert-danger alert-warning').addClass('alert-success')
      }
    else{
      if(fails > 0){
        $(this).removeClass('alert-success alert-danger alert-warning').addClass('alert-danger')
      }
      else{
        $(this).removeClass('alert-success alert-danger alert-warning')
      }
    }
    return true
    })
    //this.enablesubmit()
  }
  
  
  
}